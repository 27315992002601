$dark-base: #171719;
$lightdark-base: #2a2c31;
$blue: #1585c4;

// widths and heights
$toolBarWidth: 8%;
$headerWidth: 80%;
$layersWidth: 12%;

$headerHeight: 10%;
$canvasContentHeight: 90%;
