@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-Regular.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'EB Garamond';
  src: url('./fonts/EBGaramond-Regular.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Jost';
  src: url('./fonts/Jost-Regular.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Regular.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Bodoni Moda';
  src: url('./fonts/BodoniModa-VariableFont_opsz\,wght.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Martian Mono';
  src: url('./fonts/MartianMono-Regular.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Gothic A1';
  src: url('./fonts/GothicA1-Regular.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Source Serif 4';
  src: url('./fonts/SourceSerif4-VariableFont_opsz\,wght.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Arvo';
  src: url('./fonts/Arvo-Regular.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Libre Franklin';
  src: url('./fonts/LibreFranklin-VariableFont_wght.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Regular.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'La Belle Aurore';
  src: url('./fonts/LaBelleAurore-Regular.ttf');
  font-display: swap;
}
