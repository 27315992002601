@use '../../styles/shared' as v;

.container {
  max-width: v.$max-width;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.button {
  align-self: flex-start;
}
