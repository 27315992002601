@use '../../styles/shared' as v;

.logo {
  width: 100px;

  @media screen and (max-width: 500px) {
    width: 80px;
  }
}
.list {
  a {
    text-decoration: none !important;
  }
}

.logoBig {
  width: 90px;
}
.logoSmall {
  width: 30px;
}

.itemButton {
  padding: 0 10px;
  min-height: 50px;
}

.itemIcon {
  justify-content: center;
  color: v.$primary-color !important;
}

.itemText {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}
