@use '../../styles/shared' as v;

// bug - have to use !important otherwise css not applying?
.container {
  max-width: v.$max-width !important;
  margin: 0 auto !important;
  padding: 0 !important;
}

.cell {
  font-weight: bold !important;
  font-size: 13px !important;
  color: #fff !important;
}

.link {
  color: rgb(27, 63, 130);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.header {
  background-color: rgb(27, 63, 130);
}

.bottom {
  display: flex;
  width: 100%;
  padding: 10px;
}

.row {
  position: relative;

  &:hover > .delete {
    visibility: inherit;
  }
}

.delete {
  position: absolute !important;
  right: 3px;
  top: 6px;
  visibility: hidden;
}
