@use '../../../../styles/shared.scss';

.container {
  width: 98%;
  max-width: 1340px;
  align-items: stretch !important;

  img {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
    object-fit: contain;
    transition: filter 0.4s;
  }
}

.live {
  margin-left: 5px;
  // live animation
  div {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin: 0 auto;
    border: 3px solid rgb(0, 128, 0);
    -webkit-animation: live 2.2s infinite ease-in-out;
    animation: live 2.2s infinite ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    &:nth-child(1) {
      background-color: rgba(0, 128, 0, 0.3);
      background-color: rgb(0, 128, 0);
      -webkit-animation-delay: -0.1s;
      animation-delay: -0.1s;
    }
  }
}

@-webkit-keyframes live {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0.6);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes live {
  0%,
  80%,
  100% {
    transform: scale(0.6);
    -webkit-transform: scale(0.6);
  }
  40% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
