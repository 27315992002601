@use '../../shared' as v;

.imageFormContainer {
  position: absolute;
  z-index: 11;
  top: 0;
  left: v.$toolBarWidth;
  height: 100%;
  width: calc(100% - v.$toolBarWidth);
  background-color: v.$lightdark-base;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 10% 90%;
  align-items: center;
  justify-content: center;
}
.closeBtn {
  position: relative;
  right: 0;
  width: 5%;
  color: #fff;
  border: none;
  background-color: transparent;
  > svg {
    transform: scale(1.3);
  }
}
.content {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 25% 75%;
  grid-template-rows: 100%;
}
.mediaListWrp {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: 90% 10%;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  > button {
    background-color: v.$blue;
    color: #fff;
    border: none;
    width: 75%;
    height: 50%;
    margin-left: 12.5%;
  }
}
.mediaList {
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  gap: 10px;
}
.imgListItem {
  position: relative;
  height: 16vh;
  display: grid;
  grid-template-rows: 20% 80%;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  color: #ffffff;
  box-sizing: border-box;
  > p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    height: 100%;
  }
}
.mediaImg {
  max-height: 100%;
  width: 50%;
  margin-left: 25%;
}
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4vh;
  width: 100%;
  background-color: red;
  > button {
    font-size: 12px;
    width: 14vw;
    padding: 10px;
    color: v.$blue;
    border: none;
    background: #fff;
  }
}

.customFileUpload {
  cursor: pointer;
  display: grid;
  border: 2px solid #fff;
  grid-template-columns: 20% 80%;
  grid-template-rows: 5vh;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 3px 6px;
  color: #fff;
  p {
    padding: 0;
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    text-align: center;
  }
  input[type='file'] {
    display: none;
  }
}
.svgWrp {
  display: flex;
  align-items: center;
  justify-content: center;
}
