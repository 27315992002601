@use '../../styles/shared' as v;

.preview {
  background: linear-gradient(to left top, rgb(21, 141, 192), rgb(20, 130, 200));
  border-radius: v.$border-radius;
  color: #fff;
  font-weight: bold;
  font-size: 1.5rem;
  margin: 1rem 0;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 780px;
  position: relative;
}

.location {
  left: 30px;
  top: 30px;
}

.icon {
  width: 100px;
  height: 100px;
}

.playersNumber {
  border: 1px solid #7d7d7d;
  border-radius: 3px;
  padding: 2px 6px;
}
