@use '../../shared' as v;

$itemsSpacing: 2vh;

.container {
  position: absolute;
  z-index: 10;
  top: v.$headerHeight + 10%;
  left: v.$toolBarWidth;
  width: 10vw;
  background-color: v.$lightdark-base;
  padding-bottom: $itemsSpacing;
  ul {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding-left: 1vw;
    li {
      display: grid;
      grid-template-columns: 20% 80%;
      align-items: center;
      justify-content: center;
      gap: 12px;
      margin-top: $itemsSpacing;
      padding: 8px;
      color: #fff;
      cursor: pointer;
      svg {
        path {
          fill: #fff;
          stroke: #fff;
        }
      }
    }
  }
}
