.container {
  width: 98%;
  max-width: 1340px;
  align-items: stretch !important;

  img {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
    object-fit: contain;
    cursor: pointer;
    transition: 0.4s transform;

    &:hover {
      transform: scale(1.01);
    }
    &:active {
      transform: scale(0.99);
    }
  }
}
