@use '../../styles/shared.scss';

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // logo
  img {
    width: 150px;
    object-fit: cover;
    margin-bottom: 20px;
  }
}

.content {
  width: 95%;
  max-width: 500px;
}
