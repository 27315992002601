@use '../../../../styles/shared' as v;

.list {
  width: 100%;
  list-style: none;
  border: 1px solid #ccc;
  padding: 5px;
  border-bottom-left-radius: v.$border-radius;
  border-bottom-right-radius: v.$border-radius;
}

.item {
  margin: 15px 0;
  padding: 5px;
  cursor: pointer;
  text-align: left;

  &:hover {
    background-color: rgb(231, 231, 238);
  }

  small {
    color: rgb(134, 134, 134);
  }
}
