@use '../../shared' as v;

.loadTemplateWrp {
  display: grid;
  grid-template-rows: 8% 87% 5%;
  border-right: 2px solid v.$blue;
  .loadTemplateHeader {
    display: grid;
    grid-template-columns: 90% 10%;
    align-items: center;
    justify-content: center;
    > p {
      font-size: 1.4rem;
      font-weight: bold;
      color: #fff;
    }
    button {
      cursor: pointer;
      background-color: transparent;
      border: none;
      color: red;
      > * {
        transform: scale(0.8);
      }
    }
  }
  .noTemplateText {
    color: #fff;
  }
}
.saveTemplateWrp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  align-items: center;
  form {
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
  }
  > img {
    width: 100%;
    border: 2px solid #000;
  }
}
.btnOptions {
  display: flex;
  flex-direction: row;
  gap: 5%;
  width: 20vw;
  button {
    flex: 1;
    padding: 4px;
    border: none;
    color: #fff;
    text-transform: uppercase;
  }
  .saveBtn {
    background-color: green;
  }
  .cancelBtn {
    background-color: red;
  }
}
.templatesList {
  grid-row: 2 / 3;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 72vh;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  .eligibableLayer {
    background-color: rgb(91, 91, 91);
  }
}
.templateItem {
  display: grid;
  grid-template-rows: 20% 80%;
  align-items: center;
  justify-content: center;
  height: 20vh;
  padding: 4px;
  cursor: pointer;
  p {
    font-size: 0.8rem;
    text-align: center;
    color: #fff;
  }
  > img {
    height: 100%;
    border: 2px solid #fff;
  }
}
.loadBtn {
  margin-left: 25%;
  width: 50%;
  background-color: #fff;
  color: v.$blue;
  border: none;
}
