@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
  font-family: HelveticaNow;
  src: url('../../public/fonts/HelveticaNow.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: HelveticaNow;
  src: url('../../public/fonts/HelveticaNowText-Light.ttf') format('truetype');
  font-weight: lighter;
}
@font-face {
  font-family: HelveticaNow;
  src: url('../../public/fonts/HelveticaNowText-Medium.ttf') format('truetype');
  font-weight: 500;
}

/* css reset */
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  height: 100%;
}

* {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f4f4;
  height: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  height: 100%;
}

.hide-scrollbars {
  overflow: scroll; /* Ensure scrolling works */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.hide-scrollbars::-webkit-scrollbar {
  display: none; /* Hide scrollbar in WebKit browsers */
}
