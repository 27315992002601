.container {
  width: 98%;
  height: 50vh;
  min-height: 600px;
  max-width: 1340px;
  display: block !important;
  margin: 2rem 0;
}

.buttonGroup {
  margin: 2rem 0;
}

.row,
.colorGroupRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.row {
  aspect-ratio: 340 / 25;
}
.colorGroupRow {
  aspect-ratio: 340 / 10;
}

.colorGroupImage {
  flex: 1;
  border-right: 1px solid #8e8e8e;
  border-bottom: 1px solid #8e8e8e;
  border-left: 1px solid #8e8e8e;
  height: 100%;
  cursor: pointer;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &:hover {
    filter: brightness(80%);
  }
}

.col {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  position: relative;
}

.colOverlay {
  position: absolute;
  width: 95%;
  height: 87%;
  bottom: 0;
}

.merge {
  width: 15px;
  height: 15px;
  background-color: rgb(0, 128, 0);
  border-radius: 50%;
  position: absolute;
  top: 38%;
  right: -8.5px;
  z-index: 10;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.delete {
  width: 17px;
  height: 17px;
  background-color: rgb(255, 0, 0);
  border-radius: 50%;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown {
  margin-top: 100px;
  z-index: 10;
  width: 100%;
  max-width: 170px;
  padding: 1px 5px;
  border-radius: 4px;
  cursor: pointer;

  transition: 0.5s transform, 0.1s visibility;
}

@media screen and (max-width: 1200px) {
  .dropdown {
    margin-top: 80px;
  }
}
@media screen and (max-width: 1050px) {
  .dropdown {
    margin-top: 70px;
  }
}
@media screen and (max-width: 900px) {
  .dropdown {
    margin-top: 60px;
  }
}
@media screen and (max-width: 750px) {
  .dropdown {
    margin-top: 50px;
  }
}
@media screen and (max-width: 600px) {
  .dropdown {
    margin-top: 40px;
  }
}
@media screen and (max-width: 450px) {
  .dropdown {
    margin-top: 30px;
  }
}

/* image */
.dropdown div {
  border: 1px solid #ccc;
  padding: 8px 8px 4px 8px;
  background-color: #fff;
}

.col img {
  width: 100%;
  height: 100%;
  max-height: 100px;
  // object-fit: cover;
  // background-color: rgb(108, 136, 197);
}

.previewImage {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
  object-fit: contain;
}
