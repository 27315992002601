@use '../../styles/shared' as v;

// bug - have to use !important otherwise css not applying?
.container {
  max-width: v.$max-width !important;
  margin: 0 auto !important;
  padding: 0 !important;
  background-color: #fff;
  border-left: 1px solid #d2d8e4;
  border-right: 1px solid #d2d8e4;
  border-bottom: 1px solid #d2d8e4;
  border-radius: 0 0 0.6rem 0.6rem;
}

.cell {
  font-weight: bold !important;
  font-size: 13px !important;
  color: #fff !important;
  border-top-left-radius: 0px !important;
}

.link {
  color: rgb(27, 63, 130);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.header {
  background-color: rgb(27, 63, 130);
}

.bottom {
  display: flex;
  width: 100%;
  padding: 10px;
}
