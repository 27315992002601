@use '../../shared' as v;

.colorPaletteModuleContainer {
  position: absolute;
  z-index: 8;
  bottom: v.$headerHeight;
  left: v.$toolBarWidth;
  min-width: 10vw;
  max-width: 16vw;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
.header {
  display: grid;
  grid-template-columns: 80% 20%;
  justify-content: center;
  align-items: center;
  height: 5vh;
  background-color: v.$lightdark-base;
  color: #fff;
  margin-bottom: 4px;
  > button {
    background-color: transparent;
    border: none;
    color: #fff;
  }
}
p {
  margin: 0;
  padding: 0;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.title {
  font-size: 1rem;
  font-weight: bold;
  text-align: left;
  width: 100%;
  padding-left: 6px;
  color: #fff;
}
.listOfPalettes {
  padding: 0 4px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  > li {
    padding-bottom: 4px;
    border-bottom: 1px solid #000;
    &:last-child {
      border-bottom: none;
    }
  }
}
.colorPalette {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 4px;
  margin-bottom: 4px;
  > button {
    opacity: 0;
    position: absolute;
    right: 0;
    background-color: transparent;
    border: none;
    color: red;
  }
  &:hover {
    > button {
      transition: all 0.25s ease-in;
      opacity: 1;
    }
  }
}
.colorsList {
  display: flex;
  flex-direction: row;
  min-height: 25px;
  flex-wrap: wrap;
  gap: 4px;
  width: 100%;
}
.color {
  width: 20px;
  height: 20px;
  > span {
    display: block;
    border-radius: 50%;
    height: 100%;
    width: 100%;
  }
}
// creating a new color palette module
.colorPaletteCreationModule {
  position: absolute;
  z-index: 10;
  top: v.$headerHeight + 2%;
  left: v.$toolBarWidth;
  width: 20vw;
  min-height: 10vh;
  max-height: 40vh;
  max-width: 225px;
  // height: 42vh;
  // display: grid;
  // grid-template-rows: 10% 80% 10%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  text-align: center;
  font-size: 0.8rem;
}
.formHeader {
  width: 100%;
  background-color: v.$lightdark-base;
  color: #fff;
  display: grid;
  grid-template-columns: 90% 10%;
  justify-content: center;
  align-items: center;
  padding: 2px;
  > p {
    text-align: center;
    font-weight: bold;
  }
  > button {
    background-color: transparent;
    color: #fff;
    padding: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    > * {
      transform: scale(0.8);
    }
  }
}
.form {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
  // display: grid;
  // grid-template-rows: 15% 75% 10%;
  padding: 4px;
}
.titleInput {
  border: none;
  border-bottom: 1px solid #000;
}
.submitBtn {
  padding: 8px 0;
  width: 40%;
  background-color: v.$lightdark-base;
  color: #fff;
  border: none;
  margin-left: 30%;
  border-radius: 12px;
}
.addColorBtn {
  border-radius: 50%;
  color: v.$blue;
  border: none;
  height: 100%;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}
.newColorsList {
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow-y: scroll;
  max-height: 20vh;
  input[type='text'] {
    border: none;
    background-color: transparent;
  }
}
.colorRow {
  display: grid;
  grid-template-columns: 10% 70% 20%;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
  border-bottom: 1px solid gray;
  height: 5vh;
  > button {
    background-color: transparent;
    border: none;
    padding: 0;
    opacity: 0;
    color: red;
    &:hover {
      transition: all 0.25s ease-in;
      opacity: 1;
    }
    > * {
      transform: scale(0.8);
    }
  }
  &:last-child {
    border-bottom: none;
  }
}
.colorPickerWrp {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
// add to palette
.addToColorPaletteContainer {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
}
.menuContext {
  display: block;
  width: 10vw;
  position: absolute;
  z-index: 10;
  ul {
    display: flex;
    flex-direction: column;
    li {
      padding: 2px;
      padding-top: 0.8vh;
      text-align: center;
      height: 4vh;
      color: #fff;
      background-color: v.$lightdark-base;
      &:hover {
        background-color: #000;
      }
    }
  }
}
