@use '../../shared' as v;

.iconBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 6px;
  color: #fff;
  padding: 6px 8px;
  background-color: v.$dark-base;
  cursor: pointer;
}
