.container {
  & > *:nth-child(odd) {
    background: linear-gradient(to left top, rgb(21, 132, 179), rgb(10, 110, 172));
  }
}

.row {
  display: flex;
  align-items: center;
  padding: 0 5px;

  & > * {
    flex: 1;
  }

  // weather icon
  img {
    width: 60px;
    height: 60px;
    margin: -5px 0;
  }
  // text
  span {
    font-size: 0.8rem;
  }
}

.high,
.low {
  text-align: center;
}

.low {
  color: #ccc;
}

.highLowContainer {
  display: flex;
  width: 80%;
  justify-content: space-between;
  height: 30px;
  align-items: center;
}
