.live {
  margin-left: 5px;
  // live animation
  div {
    width: 15px;
    height: 15px;
    border-radius: 100%;
    margin: 0 auto;

    -webkit-animation: live 2.2s infinite ease-in-out;
    animation: live 2.2s infinite ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  .activeDot {
    border: 3px solid rgb(0, 128, 0);
    &:nth-child(1) {
      background-color: rgba(0, 128, 0, 0.3);
      background-color: rgb(0, 128, 0);
      -webkit-animation-delay: -0.1s;
      animation-delay: -0.1s;
    }
  }
  .deactivedDot {
    border: 3px solid rgb(128, 0, 0);
    &:nth-child(1) {
      background-color: rgba(128, 0, 0, 0.3);
      background-color: rgb(128, 0, 0);
      -webkit-animation-delay: -0.1s;
      animation-delay: -0.1s;
    }
  }
}

@-webkit-keyframes live {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0.6);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes live {
  0%,
  80%,
  100% {
    transform: scale(0.6);
    -webkit-transform: scale(0.6);
  }
  40% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
