@use '../../styles/shared.scss' as v;

.carousel {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: stretch;

  h2 {
    font-weight: 800;
    font-size: 2.8rem;
    text-transform: uppercase;
    margin: 1px 0;
  }

  // title
  h3 {
    font-weight: 500;
    margin: 10px 0;
  }
  a {
    color: #fff;
    text-decoration: none;
  }
}

.red {
  color: rgb(169, 53, 72);
}
.blue {
  color: v.$primary-color;
}
.green {
  color: rgb(108, 178, 86);
}

.iconCard {
  width: 130px;
  height: 130px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: v.$border;
  border-radius: v.$border-radius;
  margin: 1rem;

  @media screen and (max-width: 820px) {
    width: 90px;
    height: 90px;
    margin: 0.5rem 0.8rem;
  }

  .icon {
    width: 100%;
    object-fit: cover;
  }
}

.floorContainer {
  @media screen and (max-width: 850px) {
    flex-direction: column;
  }
}

.floor {
  width: 75% !important;

  @media screen and (max-width: 1000px) {
    width: 100% !important;
  }
  @media screen and (max-width: 850px) {
    width: 50% !important;
  }
}
