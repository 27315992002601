@use '../../../../styles/shared' as v;

.accordion {
  width: 98%;
  max-width: 1340px;
  margin: 20px 0;
  padding: 10px 0;
  border-radius: v.$border-radius !important;
  box-shadow: v.$box-shadow !important;
  border: v.$border !important;
}

.container {
  width: 98%;
  max-width: 1340px;
  margin: 2rem 0;

  @media screen and (max-width: 800px) {
    display: none !important;
  }
}

.layer {
  background-color: #000;
}

.customImage {
  width: 550px;
  height: 100px;
  background-color: transparent;
  color: #fff;
  padding: 8px 0 0 25px;
  font-size: 1.9rem;
  position: relative;

  h1 {
    word-wrap: break-word;
    overflow: hidden;
    font-family: HelveticaNow, sans-serif;
    font-weight: 500;
  }
}

.title {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 1.1em;
}

.icon {
  margin-left: 5px;
  color: rgb(140, 140, 140);
}
