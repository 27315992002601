@use '../../styles/shared.scss' as v;

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.row {
  width: 100%;
  margin: 5px 0;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.link {
  color: v.$primary-color;

  &:hover {
    color: rgb(24, 68, 171);
  }
}
