@use './shared' as v;
// $toolBarWidth: 8%;
// $headerWidth: 80%;
// $layersWidth: 12%;

// $headerHeight: 10%;
// $canvasContentHeight: 90%;
.contentCreationContainer {
  position: relative;
  background-color: v.$dark-base;
  display: grid;
  grid-template-columns: v.$toolBarWidth v.$headerWidth v.$layersWidth;
  grid-template-rows: 10% 90%;
  height: 88vh;
  width: 90vw;
}
// CC LOADER
.allPlayersContainer {
  background-color: #fff;
  width: 100%;
  height: 85vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5%;
  > h2 {
    color: v.$blue;
    font-size: 1.8rem;
  }
  > select {
    color: v.$blue;
    border: 2px solid v.$blue;
    padding: 4px 6px;
    font-size: 1.1rem;
    border-radius: 4px;
  }
}
.btn {
  border: none;
  background-color: v.$blue;
  color: #fff;
  padding: 4px 12px;
  border-radius: 6px;
}

.dimensionsWrp {
  position: absolute;
  bottom: 6px;
  right: v.$layersWidth + 0.5%;
  color: #fff;
  font-size: 0.8rem;
}
// SIDE BAR TOOLS
.sidebarToolsContainer {
  box-sizing: border-box;
  padding: 0;
  padding-top: 9vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  list-style: none;
  background-color: v.$lightdark-base;
  > li {
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    justify-content: center;
    color: #fff;
    > p {
      font-size: 12px;
    }
  }
}

// LAYERS CONTAINER
.layersContainer {
  grid-column: 3 / 4;
  grid-row: 1 / end;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 10% 90%;
  background-color: v.$dark-base;
}

// layer
.layerContainer {
  position: relative;
  height: 12%;
  width: 100%;
  // border: 1px solid gray;
  canvas {
    display: none;
    background-color: red;
  }
  img {
    width: 100%;
    height: 100%;
  }
}

// canvas
.canvasWrp {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.canvasWrpBackground {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: transparent;
}
.canvas {
  position: absolute;
  z-index: 2;
}
