@use '../../styles/shared' as v;

.button {
  margin-top: 10px;
  align-self: flex-start;
}
.container {
  max-width: v.$max-width;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
