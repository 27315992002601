@use '../../shared' as v;

// HEADER
.header {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  background-color: v.$lightdark-base;
  display: grid;
  align-items: center;
  grid-template-columns: 25% 25% 40%;
  gap: 2%;
  font-size: 12px;
  background-color: 'red';
}
.btnTitleWrp {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  p {
    font-size: 12px;
    color: #fff;
  }
}
.documentTitle {
  color: #fff;
  font-size: 12px;
  border: none;
  background-color: transparent;
}
.documentGroupWrp {
  display: grid;
  padding-left: 2%;
  grid-template-columns: 18% 70%;
  gap: 5%;
  align-items: center;
  color: v.$blue;
  > p:nth-child(2) {
    border: 2px solid v.$blue;
    text-align: center;
    padding: 4px;
  }
}
.headerTabs {
  display: flex;
  justify-content: space-around;
  padding-left: 10%;
}
.savingPopup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 11;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
}
